<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                Edit Service
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <form>
                    <v-autocomplete
                            v-model="editedData.fromCurrency"
                            :error-messages="fromCurrencyErrors"
                            label="From Currency"
                            required
                            :items="selectData.destinations"
                            @input="$v.editedData.fromCurrency.$touch()"
                            @blur="$v.editedData.fromCurrency.$touch()"
                            item-text="value"
                            item-value="id"
                            @change="changeData"
                    ></v-autocomplete>

                    <v-autocomplete
                            v-model="editedData.toCurrency"
                            required
                            label="To Currency"
                            :items="destinations"
                            item-text="value"
                            item-value="id"
                            :error-messages="toCurrencyErrors"
                            @input="$v.editedData.toCurrency.$touch()"
                            @blur="$v.editedData.toCurrency.$touch()"
                    ></v-autocomplete>
                    <v-text-field
                            v-model="editedData.buyingRate"
                            :error-messages="buyingRateErrors"
                            type="number"
                            label="Buying Rate"
                            required
                            @input="$v.editedData.buyingRate.$touch()"
                            @blur="$v.editedData.buyingRate.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.minAmount"
                            :error-messages="minAmountErrors"
                            type="number"
                            label="Minimum Amount"
                            required
                            @input="$v.editedData.minAmount.$touch()"
                            @blur="$v.editedData.minAmount.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.maxAmount"
                            :error-messages="maxAmountErrors"
                            type="number"
                            label="Maximum Amount"
                            required
                            @input="$v.editedData.maxAmount.$touch()"
                            @blur="$v.editedData.maxAmount.$touch()"
                    ></v-text-field>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">Cancel</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required, maxLength} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "editDivisionForm",
        props: ["success", 'serviceID'],
        computed: {
            fromCurrencyErrors() {
                const errors = [];
                if (!this.$v.editedData.fromCurrency.$dirty) return errors;
                !this.$v.editedData.fromCurrency.required &&
                errors.push("From Name is required");
                !this.$v.editedData.fromCurrency.maxLength && errors.push('From must be at most 3 characters long')

                return errors;
            },
            toCurrencyErrors() {
                const errors = [];
                if (!this.$v.editedData.toCurrency.$dirty) return errors;
                !this.$v.editedData.toCurrency.required &&
                errors.push("To Name is required.");
                !this.$v.editedData.toCurrency.maxLength && errors.push('To must be at most 3 characters long')
                return errors;
            },
            buyingRateErrors() {
                const errors = [];
                if (!this.$v.editedData.buyingRate.$dirty) return errors;
                !this.$v.editedData.buyingRate.required &&
                errors.push("Buying Rate is required.");
                return errors;
            },
            minAmountErrors() {
                const errors = [];
                if (!this.$v.editedData.minAmount.$dirty) return errors;
                !this.$v.editedData.minAmount.required &&
                errors.push("Minimum Rate is required.");
                return errors;
            },
            maxAmountErrors() {
                const errors = [];
                if (!this.$v.editedData.maxAmount.$dirty) return errors;
                !this.$v.editedData.maxAmount.required &&
                errors.push("Maximum Rate is required.");
                return errors;
            }
        },
        data() {
            return {
                destinations: [],
                selectData: {
                    destinations: [],
                    selectedValue: null
                },
                province: [],
                show: false,
                updated: false,
                editedData: {
                    fromCurrency: "",
                    toCurrency: "",
                },
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {
            editedData: {
                fromCurrency: {required, maxLength: maxLength(3)},
                toCurrency: {required, maxLength: maxLength(3)},
                buyingRate: {required},
                minAmount: {required},
                maxAmount: {required},
            }
        },
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            async formData() {
                axios.get('Shared/GetCurrencyList').then(response => {
                    this.selectData.destinations = response.data
                    this.destinations = response.data;
                    console.log(this.destinations)
                }).catch(err => {
                    console.log(err)
                })

                this.updated = false;
                let serviceData = await axios.get(`MoneyExchange/GetBuyingExchangeRateByIDAsync/${this.serviceID}`);
                this.editedData = serviceData.data
            },
            changeData(val) {
                this.destinations = this.selectData.destinations.filter(destination => destination.value !== val);
                console.log(this.selectData.destinations)
            },
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        buyingRateID: this.editedData.buyingRateID,
                        fromCurrency: this.editedData.fromCurrency,
                        ToCurrency: this.editedData.toCurrency,
                        buyingRate: parseInt(this.editedData.buyingRate),
                        minAmount: parseInt(this.editedData.minAmount),
                        maxAmount: parseInt(this.editedData.maxAmount),
                    };
                    axios.post("MoneyExchange/UpdateBuyingExchangeRateAsync", param).then(response => {
                        if (response.data.success) {
                            this.$v.$reset();
                            this.editedData = {};
                            this.servicesData.data = response.data
                            this.servicesData.message = "Buying Rate updated Succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.fromCurrency = "";
                this.editedData.toCurrency = "";
            }
        }
    };
</script>

<style scoped>
</style>